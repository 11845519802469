<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col cols="2" sm="2" md="2" lg="2">
          <h2>実態調査一覧</h2>
        </v-col>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <YearPicker
            label="対象年月"
            v-model="searchCondition.targetYear"
            format="YYYY年"
            selectType="month"
            :clearable="false"
            :hideDetails="true"
            suffix="度"
            :changeEvt="search"
          ></YearPicker>
        </v-col>
<!--
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="200" :disabled="loading" @click="fileDownload('返答・集計', '/storage/zip/返答・集計.zip')"><v-icon :small="true">mdi-download</v-icon>返答・集計ダウンロード</v-btn>
        </v-col>
-->
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="answerDataList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border"
            height="calc(100vh - 200px)"
          >
            <template v-slot:item="{ item }">
              <!-- <tr :key="item.memberNo+'_'+item.sid" :class="[item.memberNo == memberNo ? 'selected-color' : '']"> -->
              <tr :key="item.memberNo+'_'+item.sid">
                <td class="center">
                  {{item.memberNo}}
                </td>
                <td class="left">
                  {{item.companyName}}
                </td>
                <td class="left">
                  {{item.officeName}}
                </td>
                <td class="left">
                  {{item.ansPeriodFrom | formatDateW}} ～ {{item.andPeriodTo | formatDateW}}<span v-if="!isBetweenDate(item.ansPeriodFrom, item.andPeriodTo)" class="text-error">受付期間外</span>
                </td>
                <td class="center min-width-200">
                  <v-btn small class="primary" :height="20" :width="100" :disabled="loading" @click="goto_detail('FactFindingEdit', item.sid, item.memberNo)">
                    <v-icon v-if="item.status==0" :small="true">mdi-border-color</v-icon>
                    <v-icon v-else-if="item.status==1" :small="true">mdi-alert</v-icon>
                    <v-icon v-else :small="true">mdi-chevron-down-circle</v-icon>
                    {{item.status == 0 ? '回答する' : 
                      item.status == 1 ? '一時保存中' : 
                      item.status == 2 ? '回答済み' : 
                                         '送信済み'}}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>


    </v-container>
  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import Message from "../Lib/Message";
import YearPicker from "./common/YearPicker.vue";
import dayjs from "dayjs";
import Mixin from "../mixins/const.js";

import FactFinding from "../model/factFinding";

export default {
  components: {
    YearPicker,
  },

  mixins: [Mixin],

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    memberNo: '',
    attribute: 0,

    // 検索条件
    searchCondition: {
      screenType: 'FactFindingList',
      companyId: null,
      associationId: null,
      companyName: '',
      targetYear: '',
    },

    // テーブルヘッダー
    headers: [
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "10%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "25%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "25%" },
        { text: '回答受付期間', align: 'center', sortable: true, value: 'ansPeriodFromTo', width: "20%" },
        { text: '回答', align: 'center', sortable: true, value: 'status', width: "20%" },
    ],

    // 回答データ一覧
    answerDataList: [
      // { memberNo: "010550", officeName: "喜茂別物流センター", address: "北海道虻田郡喜茂別町字相川84-1", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 0, sumStatus: "未集計" },
      // { memberNo: "010065", officeName: "十勝物流センター", address: "北海道河西郡芽室町東芽室北１線１１－２", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 1, sumStatus: "未集計" },
      // { memberNo: "010066", officeName: "石狩物流センター", address: "北海道小樽市銭函５－７１－１", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 2, sumStatus: "集計済" },
      // { memberNo: "020034", officeName: "八戸物流センター", address: "青森県八戸市河原木兵衛河原１０－３８", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 0, sumStatus: "未集計" },
      // { memberNo: "040032", officeName: "気仙沼冷凍工場", address: "宮城県気仙沼市朝日町８－２", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 0, sumStatus: "未集計" },
      // { memberNo: "040024", officeName: "仙台物流センター", address: "宮城県仙台市宮城野区蒲生２－３１－３", ansPeriod: "2022/09/01～2022/09/30", ansStatus: 0, sumStatus: "未集計" },
    ],



  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }
    
    // 属性による検索条件の設定
    if(this.attribute == 0) {
      // 事業所ユーザは自会社の事業所データのみ
      this.searchCondition.companyId = this.$store.getters.getCompanyId;

      // 事業所ユーザは、自分がメール宛先にある場合のみ
      this.searchCondition.email = this.$store.getters.getUserid;

    } else if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.associationId = this.memberNo;
    }

    this.init();

    //this.loadList();
  },

  mounted: function () {
  },

  methods: {
    init() {
      const str = this.$store.getters.getStoreSearchCondition;
      //if (!Object.keys(str).length > 0) {
      if (str.screenType == 'FactFindingList') {
        // 回答画面から戻った場合
        this.searchCondition = str;
      } else {
        // 初期値
        this.searchCondition.targetYear = dayjs().format().substr(0, 7);  // 実施年
      }
      
    },

    /** 表示更新 */
    async loadList() {
      Debug.log('function[loadList]');
      this.loading = true;

      try {
        const res = await FactFinding.search(this.searchCondition);
        Debug.log(res.data);
        this.answerDataList = res.data;
      } catch (error) {
        Message.err(error, "実態調査一覧を取得できませんでした");
      }
      Debug.log(this.answerDataList);

      this.loading = false;
    },

    search() {
      Debug.log("function[search]");
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      this.loadList();
    },

    /** 詳細ページに遷移 */
    goto_detail (name, surveyId, memberNo, blenk=false) {
      this.$store.commit('setTargetSurveyId', surveyId);
      this.$store.commit('setTargetMemberNo', memberNo);

      if(blenk) {
        // 別タブで開く
        let resolvedRoute = this.$router.resolve({
          name: name,
        });
        window.open(resolvedRoute.href, '_blank');
        return;
      }

      // 同タブで開く
      this.$router.push({name: name});
    },
  },

  computed: {
  },

  watch: {
  },

  filters: {
    // formatDate(date) {
    //   // YYYY/MM/DD になおす
    //   if (!date) return "";
    //   const formatDate = dayjs(date);

    //   const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][formatDate.day()];

    //   return `${formatDate.format("YYYY/MM/DD")}(${dayOfWeek})`;
    // },
  },
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

</style>
