import Util from '../Lib/Util'
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
export default {
  data: () => ({
    /** ユーザマスタ 権限 */
    authority: [
      { text: "作業者", value: "0" },
      { text: "管理者", value: "1" },
      { text: "システム管理者", value: "2" },
    ],

    /** 調査種別 */
    surveyType: [
      {text: "月次報告データ", value: 1},
      {text: "実態調査", value: 2},
      {text: "電力調査", value: 3},
      {text: "冷媒調査", value: 4},
    ],

    /** 回答状況ステータス */
    ansStatus: [
      { text: "未回答", value: 0 },
      { text: "一時保存中", value: 1 },
      { text: "確定", value: 3 },
      { text: "確認中", value: 4 },
      { text: "保留", value: 5 },
      { text: "確認済", value: 6 },
    ],

    /** 都道府県 */
    prefList : [
      { text: "北海道", value: 1 },
      { text: "青森県", value: 2 },
      { text: "岩手県", value: 3 },
      { text: "宮城県", value: 4 },
      { text: "秋田県", value: 5 },
      { text: "山形県", value: 6 },
      { text: "福島県", value: 7 },
      { text: "茨城県", value: 8 },
      { text: "栃木県", value: 9 },
      { text: "群馬県", value: 10 },
      { text: "埼玉県", value: 11 },
      { text: "千葉県", value: 12 },
      { text: "東京都", value: 13 },
      { text: "神奈川県", value: 14 },
      { text: "新潟県", value: 15 },
      { text: "富山県", value: 16 },
      { text: "石川県", value: 17 },
      { text: "福井県", value: 18 },
      { text: "山梨県", value: 19 },
      { text: "長野県", value: 20 },
      { text: "岐阜県", value: 21 },
      { text: "静岡県", value: 22 },
      { text: "愛知県", value: 23 },
      { text: "三重県", value: 24 },
      { text: "滋賀県", value: 25 },
      { text: "京都府", value: 26 },
      { text: "大阪府", value: 27 },
      { text: "兵庫県", value: 28 },
      { text: "奈良県", value: 29 },
      { text: "和歌山県", value: 30 },
      { text: "鳥取県", value: 31 },
      { text: "島根県", value: 32 },
      { text: "岡山県", value: 33 },
      { text: "広島県", value: 34 },
      { text: "山口県", value: 35 },
      { text: "徳島県", value: 36 },
      { text: "香川県", value: 37 },
      { text: "愛媛県", value: 38 },
      { text: "高知県", value: 39 },
      { text: "福岡県", value: 40 },
      { text: "佐賀県", value: 41 },
      { text: "長崎県", value: 42 },
      { text: "熊本県", value: 43 },
      { text: "大分県", value: 44 },
      { text: "宮崎県", value: 45 },
      { text: "鹿児島県", value: 46 },
      { text: "沖縄県", value: 47 },
    ],

    // 調査種別
    SURV_CITY_DATA: 1,
    SURV_FACT_FINDING: 2,
    SURV_ELECTRICITY: 3,
    SURV_REFRIGERANT: 4,

    /** 冷媒調査 */
    refrigerantUsage: [
      { text: "冷蔵庫 冷凍庫", value: 0 },
      { text: "荷捌室", value: 1 },
      { text: "製氷", value: 2 },
      { text: "凍結", value: 3 },
      { text: "その他", value: 4 },
    ],
    refrigerantMethod: [
      { text: "一元", value: 0 },
      { text: "二元", value: 1 },
    ],
    refrigerantWorker: [
      { text: "自所で充填", value: 0 },
      { text: "業者に依頼", value: 1 },
      { text: "自所と業者", value: 2 },
    ],

    /** 電力調査 */
    electricityElecCoName: [
      { text: "", value: null },
      { text: "北海道電力", value: 1 },
      { text: "東北電力", value: 2 },
      { text: "北陸電力", value: 3 },
      { text: "東京電力", value: 4 },
      { text: "中部電力", value: 5 },
      { text: "関西電力", value: 6 },
      { text: "中国電力", value: 7 },
      { text: "四国電力", value: 8 },
      { text: "九州電力", value: 9 },
      { text: "沖縄電力", value: 10 },
    ],
    electricityExemption: [
      { text: "", value: null },
      { text: "減免有", value: 1 },
      { text: "減免無", value: 2 },
    ],
    electricitySelfGeneration: [
      { text: "", value: 0 },
      { text: "燃料式発電機", value: 1 },
      { text: "太陽光", value: 2 },
      { text: "風力", value: 3 },
      { text: "その他", value: 4 },
    ],

    /** 月次報告データ */
    cityDataNo9Item: [
      { text: "生鮮水産物", value: 1 },
      { text: "冷凍水産物", value: 2 },
      { text: "塩干水産物", value: 3 },
      { text: "水産加工品", value: 4 },
      { text: "畜産物", value: 5 },
      { text: "畜産加工品", value: 6 },
      { text: "農産物", value: 7 },
      { text: "農産加工品", value: 8 },
      { text: "冷凍食品", value: 9 },
      { text: "その他", value: 10 },
      { text: "合計", value: 11 },
    ],
    cityDataImportedItem: [
      { text: "牛肉", value: 1 },
      { text: "豚肉", value: 2 },
      { text: "羊肉", value: 3 },
      { text: "馬肉", value: 4 },
      { text: "鶏肉", value: 5 },
      { text: "その他畜産物及び 畜産加工品", value: 6 },
      { text: "えび", value: 7 },
      { text: "かつお・まぐろ類", value: 8 },
      { text: "その他の水産物及び 水産加工品", value: 9 },
      { text: "農産物及び 農産加工品", value: 10 },
      { text: "冷凍食品", value: 11 },
      { text: "その他", value: 12 },
      { text: "合計", value: 13 },
    ],

    /** 協会 */
    associationType: [
      { text: "日冷倉協", value: 0},
      { text: "日冷協　", value: 1},
    ],

    /** メールテンプレート種別 */
    mailTemplateTypeList: [
      { text: '未使用', value: 0 },
      { text: '実態調査 依頼', value: 2 },
      { text: '電力調査 依頼', value: 3 },
      { text: '冷媒調査 依頼', value: 4 },
      { text: '実態調査 個別返答', value: 5 },
      { text: '協会費請求 日冷倉協', value: 6 },
      { text: '協会費請求 冷事協会', value: 7 },
      { text: 'お知らせ', value: 8 },
    ],

    /** アドレス帳 属性 */
    emailAddressAttributeList: [
      { text: '事業所会員', value: 0 },
      { text: '都道府県協会', value: 1 },
      { text: '日本冷蔵倉庫協会', value: 2 },
    ],

    //-----------------------------------------------------
    // 事業所会員情報
    // 営業自家用区分 チェックボックス
    usage: [
      { text: "-", value: 0 },
      { text: "営業用", value: 1 },
      { text: "自家用", value: 2 },
    ],
    // 陸水区分 チェックボックス
    landWater: [
      { text: "-", value: 0 },
      { text: "陸上", value: 1 },
      { text: "水産", value: 2 },
    ],

    //-----------------------------------------------------

    big5List: [
      { text: "マルハニチロ物流", value: "5334" },
      { text: "日水物流", value: "2393" },
      { text: "横浜冷凍", value: "0383" },
      { text: "東洋水産", value: "1209" },
      { text: "ニチレイ・ロジスティクス", value: "1033" },
      { text: "キョクレイ", value: "0819" },
      { text: "ロジスティクス・ネットワーク", value: "2637" },
    ],

    //-----------------------------------------------------

    // 事業所データ 変更区分
    changeCategory: [
      { text: "", value: 0 },
      { text: "入会（新設）", value: 1 },
      { text: "入会（既存）", value: 2 },
      { text: "退会（閉鎖）", value: 3 },
      { text: "退会（有効）", value: 4 },
      { text: "休会", value: 5 },
      { text: "増設", value: 6 },
      { text: "一部廃止", value: 7 },
      { text: "温度帯変更", value: 8 },
      { text: "登録訂正", value: 9 },
      { text: "その他", value: 10 },
    ],

    // 倉庫証券 発券区分
    hakkenKubun: [
      { text: "", value: 0 },
      { text: "発券", value: 1 },
      { text: "非発券", value: 2 },
    ],

    //-----------------------------------------------------

    // グループ名
    groupNameList: [
      { text: "01 ニチレイ系" },
      { text: "02 ヨコレイ系" },
      { text: "03 マルハ系" },
      { text: "04 ニッスイ系" },
      { text: "05 東洋水産系" },
      { text: "06 五十嵐冷蔵㈱" },
      { text: "07 ㈱二葉" },
      { text: "08 藤林グループ" },
      { text: "09 ㈱兵食" },
      { text: "10 C&Fﾛｼﾞﾎｰﾙﾃﾞｨﾝｸﾞｽ" },
      { text: "11 ㈱フリゴ" },
    ],

    //-----------------------------------------------------
    // division 選択
    divosionList: [
      { text: "◯", value: 1 },
      { text: "　", value: 0 },
      { text: "すべて", value: -1 },
    ],
    divosionCompanyList: [
      { text: "●", value: 1 },
      { text: "　", value: 0 },
      { text: "すべて", value: -1 },
    ],

    
    // 月次報告データ 回答データチェック・集計 対象年月 設定可能年月(より後)
    cityDataCheckDate: '2022-12',
    // 実態調査 回答データチェック・集計 対象年月 設定可能年月(より後)
    factFindingCheckDate: '2022-01',

  }),
  
  methods: {
    // 9号 品目取得
    getCityDataNo9Item(itemId) {
      let item = {text: '', value: 0};

      if(typeof itemId !== "undefined" && itemId !== null) {
        item = this.cityDataNo9Item.find(({ value }) => value == itemId);
      }

      return item;
    },

    // 輸入物資 品目取得
    getCityDataImportedItem(itemId) {
      let item = {text: '', value: 0};

      if(typeof itemId !== "undefined" && itemId !== null) {
        item = this.cityDataImportedItem.find(({ value }) => value == itemId);
      }

      return item;
    },

    // メールテンプレート種別取得
    getMailTemplateType(itemId) {
      let item = {text: '', value: 0};

      if(typeof itemId !== "undefined" && itemId !== null) {
        item = this.mailTemplateTypeList.find(({ value }) => value == itemId);
      }

      return item;
    },

    /** pref_idから都道府県名を取得し置換 */
    replacePrefIdToPrefName(pref_id) {
      // 都道府県名取得
      let prefName = '';
      if(typeof pref_id !== "undefined" && pref_id !== null) {
        prefName = this.prefList.find(({ value }) => value == pref_id).text;
      }
      return prefName;
    },

    // 回答ステータス
    getAnsStatusText(itemId) {
      let text = '';
      if(typeof itemId !== "undefined" && itemId !== null) {
        text = this.ansStatus.find(({ value }) => value == itemId).text;
      }
      return text;
    },

    // 日付の前後チェック
    checkStartEndDate(from, to) {
      const tmpStartDate = from;
      const tmpEndDate = to;

      if (!tmpStartDate || !tmpEndDate) return true;

      const startDate = Util.convStringDate(tmpStartDate+'-01', '-');
      const endDatee = Util.convStringDate(tmpEndDate+'-01', '-');
      
      if (startDate > endDatee) {
        return '開始日付 が 終了日付後です';
      }
      return true;
    },

    /** 期間の判定 */
    isBetweenDate(from, to) {
      // 現在日時がfrom,toの範囲内かチェック(範囲にfrom,toを含む)
      dayjs.extend(isBetween);
      return dayjs().startOf('day').isBetween(dayjs(from).format('YYYY-MM-DD'), dayjs(to).format('YYYY-MM-DD'), null, '[]');
    },

    /** statusから回答状況ステータスを取得し返却 */
    getStatus(status) {
      let res = {};
      if(typeof status !== "undefined" && status !== null) {
        res = this.ansStatus.find(({ value }) => value == status);
      }
      return res;
    },

    /** statusから回答状況ステータス名を取得し返却 */
    getStatusDisp(status) {
      let res = '';
      if(typeof status !== "undefined" && status !== null) {
        res = this.ansStatus.find(({ value }) => value == status).text;
      }
      return res;
    },

    // 数字のみ許可
    numOnlyInput(value, minus=false, decimal=false, decimalNum=0) {
      let tmpValue = Util.numOnly(value, minus, decimal, decimalNum)
      return tmpValue;
    },
  },

  filters: {
    formatDateW(date) {
      // YYYY/MM/DD(W) になおす
      if (!date) return "";
      const formatDate = dayjs(date);

      const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][formatDate.day()];

      return `${formatDate.format("YYYY/MM/DD")}(${dayOfWeek})`;
    },

    formatDate: (date, format="YYYY/MM/DD") => {
      // 指定formatになおす
      if (!date || date == null) return "";
      return dayjs(date).format(format);
    },

    formatJP(date, opt={era:'short',year:'numeric',month:'long',day:'numeric'}) {
      // 西暦→和暦変換
      if (!date || date == null) return "";
      const formatDate = new Date(date);
      return formatDate.toLocaleDateString("ja-JP-u-ca-japanese", opt);
    },

    /**
     * number 
     */
    // 数字のカンマ区切り(3桁ごと)
    numberFormat(value) {
      if((value === null) ||
          (value === undefined)) {
          return value;
      }
      if (typeof value !== "number" &&
          !value.match(/^\d+$/)) {
          return value;
      }
      let formatter = new Intl.NumberFormat('ja-JP');
      return formatter.format(value);
    },
  },
};
